import React, { useEffect, useState } from 'react';
import { Checkbox, Select, Textarea, TextInput, Tooltip, Datepicker } from 'flowbite-react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';

const Dropzone = ({ onDrop, uploadedFiles, onRemove, error }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (uploadedFiles.length + acceptedFiles.length > 4) {
        alert('You can only upload up to 4 files');
      } else if (uploadedFiles.length + acceptedFiles.length < 1) {
        alert('You must upload 4 files');
      } else {
        onDrop(acceptedFiles);
      }
    },
    multiple: true,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': []
    }
  });

  return (
    <>
      <div {...getRootProps()} className="border-dashed border-2 border-gray-300 rounded-md dropzone h-28  p-4 text-center cursor-pointer">
        <input {...getInputProps()} />
        <p className="text-gray-500">Click here to add files</p>
      </div>
      <div className="mt-2">
        {uploadedFiles.length > 0 && (
          <ul className="list-disc list-inside">
            {uploadedFiles.map((file, index) => (
              <li key={index} className="text-gray-700 flex justify-between px-5">
                {file.name}
                <button
                  type="button"
                  className="text-red-500 ml-4 hover:bg-gray-200 p-3 rounded-sm"
                  onClick={() => onRemove(file)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </>
  );
};

const FnolForm = () => {
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [errors, setErrors] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [proxyUploadedFiles, setProxyUploadedFiles] = useState({});
  const [fnolRequestId, setFnolRequestId] = useState(null);
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Load reCAPTCHA script with cache-busting query parameter
  useEffect(() => {
    const scriptId = 'recaptcha-script';
    const existingScript = document.getElementById(scriptId);

    // Remove the existing script if present to ensure fresh load
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = scriptId;
    // Adding cache-busting query parameter to the script URL
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit&cacheBust=${new Date().getTime()}`;
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    // Cleanup on unmount to remove the script
    return () => {
      if (document.getElementById(scriptId)) {
        document.getElementById(scriptId).remove();
      }
    };
  }, []);

  useEffect(() => {
    if (recaptchaToken) {
      fetchFormSchema(recaptchaToken);
    }
  }, [recaptchaToken]);

  const fetchFormSchema = async (token) => {
    console.log(token)
    try {
      const response = await fetch(`${process.env.REACT_APP_EXPRESS_PROXY}/api/fnol_form`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Access-Control-Allow-Methods"
        },
        body: JSON.stringify({ recaptchaToken: token }),
      });
      const data = await response.json();
      setFormSchema(data);
      initializeFormData(data);
    } catch (error) {
      console.error('Error fetching form schema:', error);
    }
  };

  const initializeFormData = (schema) => {
    console.log(schema)
    const initialData = {};
    schema.fnol_sections.forEach((section) => {
      section.fnol_questions.forEach((question) => {
        initialData[question.id] = question.response_type === 'input_multiselect' ? [] : '';
      });
    });
    setFormData(initialData);
  };

  const findQuestionById = (id) => {
    for (const section of formSchema.fnol_sections) {
      for (const question of section.fnol_questions) {
        if (question.id === id) {
          return question;
        }
      }
    }
    return null;
  };

  const handleInputChange = async (questionId, value) => {
    if (Array.isArray(value) && value[0] instanceof File) {
      const updatedFiles = await Promise.all(value.map(file => new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          resolve({ name: file.name, data: base64Data });
        };
        reader.readAsDataURL(file);
      })));
  
      setFormData((prevData) => ({
        ...prevData,
        [questionId]: [...(prevData[questionId] || []), ...updatedFiles],
      }));
  
      setProxyUploadedFiles((prevProxy) => ({
        ...prevProxy,
        [questionId]: [...(prevProxy[questionId] || []), ...value]
      }));
  
      setUploadedFiles((prevFiles) => [...prevFiles, ...value]);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [questionId]: value,
      }));
    }
  
    const question = findQuestionById(questionId);
    if (question) {
      const selectedAnswer = question.fnol_answers.find(answer => answer.answer_text === value);
      if (selectedAnswer && selectedAnswer.to_trigger_route) {
        setCurrentRoute(selectedAnswer.to_trigger_route);
      }
    }
  };

  const handleRemoveFile = (fileToRemove, questionId) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    setProxyUploadedFiles((prevProxy) => ({
      ...prevProxy,
      [questionId]: (prevProxy[questionId] || []).filter((file) => file !== fileToRemove)
    }));

    setFormData((prevData) => {
      const newFiles = (prevData[questionId] || []).filter((file) => file.name !== fileToRemove.name);
      return {
        ...prevData,
        [questionId]: newFiles,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const section = getFilteredSections()[currentStep];
    const validationResponse = await validateSection(section.id, formData);

    if (validationResponse.isValid) {
      setFormData(validationResponse.formData); // Update formData with validated and uploaded URLs
      if (currentStep < getFilteredSections().length - 1) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        // Final submission logic
        try {
          await finalSubmit();
          setIsFinalSubmit(true);
        } catch (error) {
          console.error('Final submission error:', error);
        }
      }
    } else {
      setErrors(validationResponse.errors);
    }
  };

  const validateSection = async (sectionId, data) => {
    console.log('validating fnolRequestId:', fnolRequestId)
    console.log('validating data:', data)
    try {
      const response = await fetch(`${process.env.REACT_APP_EXPRESS_PROXY}/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sectionId, formData: data, formSchema, fnolRequestId }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.fnolRequestId) {
          setFnolRequestId(result.fnolRequestId);
        }
        return { isValid: true, errors: {}, formData: result.formData };
      } else {
        const result = await response.json();
        return { isValid: false, errors: result.errors };
      }
    } catch (error) {
      console.error('Validation error:', error);
      return { isValid: false, errors: { server: 'Server error occurred' } };
    }
  };

  const finalSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_EXPRESS_PROXY}/final_submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData, fnolRequestId }),
      });

      if (!response.ok) {
        throw new Error('Final submission failed');
      }

      const result = await response.json();
      console.log('Final submission result:', result);
    } catch (error) {
      console.error('Final submission error:', error);
      throw error;
    }
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const getFilteredSections = () => {
    return formSchema.fnol_sections.filter(section => 
      section.for_routes === null || section.for_routes === currentRoute
    );
  };

  if (!formSchema) {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-1 space-y-8 p-4 sm:p-8">
          <div className="p-6 border rounded-lg shadow-lg bg-white">
            <h2 className="text-xl font-semibold mb-4 text-marian-blue">Please verify you are not a robot</h2>
            {/* Only render ReCAPTCHA once the script is fully loaded */}
            {scriptLoaded && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(token) => setRecaptchaToken(token)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  if (isFinalSubmit) {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-1 space-y-8 p-4 sm:p-8">
          <div className="p-6 border rounded-lg shadow-lg bg-white">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div dangerouslySetInnerHTML={{ __html: formSchema.success_page }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const filteredSections = getFilteredSections();
  const section = filteredSections[currentStep];

  // Sort questions by position
  const sortedQuestions = section.fnol_questions.sort((a, b) => a.position - b.position);

  // Calculate progress percentage
  const progress = ((currentStep + 1) / filteredSections.length) * 100;

  return (
    <div className="flex flex-col min-h-screen">
      <form onSubmit={handleSubmit} className="flex-1 space-y-8 p-4 sm:p-8">
        <div className="p-6 border rounded-lg shadow-sm bg-white">
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-russian-violet bg-pear">
                  Section {currentStep + 1} of {filteredSections.length}
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pear"
              ></div>
            </div>
          </div>
          <h2 className="text-xl font-semibold mb-4 text-marian-blue">{section.title}</h2>
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: section.description }} />
          {sortedQuestions.map((question) => (
            <div key={question.id} className="mb-4">
              {question.response_type !== 'input_checkbox' && (
                <label className="block text-sm font-medium text-gray-700" htmlFor={`question-${question.id}`}>
                  <div className='flex'>
                    <span dangerouslySetInnerHTML={{ __html: question.question_text }} />
                    {question.extra_info && (
                      <Tooltip 
                      content={question.extra_info} 
                      placement="top"
                      className="absolute z-10 w-max-md inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm"
                      base="absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm"
                      >
                        <FontAwesomeIcon icon={faCircleInfo} className='ms-2' />
                      </Tooltip>
                    )}
                  </div>
                </label>
              )}
              {question.response_type === 'input_file' ? (
                <Dropzone 
                  onDrop={(files) => handleInputChange(question.id, files)} 
                  uploadedFiles={proxyUploadedFiles[question.id] || []} 
                  onRemove={(file) => handleRemoveFile(file, question.id)} 
                  error={errors[question.id]} 
                />
              ) : (
                renderInputField(question, formData[question.id] || '', handleInputChange, errors[question.id])
              )}
              {question.footer_info && (
                <div className="text-sm text-gray-500 mt-2" dangerouslySetInnerHTML={{ __html: question.footer_info }} />
              )}
            </div>
          ))}
          <div className="flex justify-between mt-auto">
            {currentStep > 0 && (
              <button type="button" onClick={prevStep} className="px-4 py-2 bg-russian-violet text-white rounded hover:bg-gray-400">
                Previous
              </button>
            )}
            {currentStep < filteredSections.length - 1 ? (
              <button type="submit" className="px-4 py-2 bg-marian-blue text-white rounded hover:bg-dark-cyan ml-auto">
                Next
              </button>
            ) : (
              <button type="submit" className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 ml-auto">
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const renderInputField = (question, value, handleInputChange, error) => {
  const inputId = `question-${question.id}`;
  return (
    <div>
      <div>
        {getInputField(question, value, handleInputChange, inputId)}
      </div>
      {error && (
        <div className="text-red-500 text-sm" dangerouslySetInnerHTML={{ __html: error }} />
      )}
    </div>
  );
};

const getInputField = (question, value, handleInputChange, inputId) => {
  switch (question.response_type) {
    case 'input_text':
      return (
        <TextInput
          id={inputId}
          type="text"
          value={value}
          onChange={(e) => handleInputChange(question.id, e.target.value)}
          className="mt-1 block w-full"
        />
      );
    case 'input_email':
      return (
        <TextInput
          id={inputId}
          type="email"
          value={value}
          onChange={(e) => handleInputChange(question.id, e.target.value)}
          className="mt-1 block w-full"
        />
      );
    case 'input_textarea':
      return (
        <Textarea
          id={inputId}
          value={value}
          onChange={(e) => handleInputChange(question.id, e.target.value)}
          className="mt-1 block w-full"
        />
      );
    case 'input_select':
      return (
        <Select
          id={inputId}
          value={value}
          onChange={(e) => handleInputChange(question.id, e.target.value)}
          className="mt-1 block w-full"
        >
          <option value="">Select an option</option>
          {question.fnol_answers.map((answer) => (
            <option key={answer.id} value={answer.answer_text}>
              {answer.answer_text}
            </option>
          ))}
        </Select>
      );
    case 'input_multiselect':
      return (
        <div id={inputId} className="mt-1 block w-full">
          {question.fnol_answers.map((answer) => (
            <div key={answer.id} className="flex items-center">
              <Checkbox
                id={`${inputId}-${answer.id}`}
                checked={value.includes(answer.answer_text)}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, answer.answer_text]
                    : value.filter((v) => v !== answer.answer_text);
                  handleInputChange(question.id, newValue);
                }}
                className="mt-1"
              />
              <label htmlFor={`${inputId}-${answer.id}`} className="ml-2">
                {answer.answer_text}
              </label>
            </div>
          ))}
        </div>
      );
    case 'input_checkbox':
      return (
        <div className='flex'>
          <Checkbox
            id={inputId}
            checked={value}
            onChange={(e) => handleInputChange(question.id, e.target.checked)}
            className="mt-1 block me-5"
          />
          {question.question_text}
        </div>
      );
      case 'input_date':
        return (
          <Datepicker
            id={inputId}
            selected={value ? new Date(value) : null}
            onChange={(date) => handleInputChange(question.id, date)} 
            maxDate={new Date()}
            className="mt-1 block w-full"
          />
        );
      
    case 'input_number':
      return (
        <TextInput
          id={inputId}
          type="number"
          value={value}
          onChange={(e) => handleInputChange(question.id, e.target.value)}
          className="mt-1 block w-full"
        />
      );
    case 'input_phone_number':
      return (
        <TextInput
          id={inputId}
          type="tel"
          value={value}
          onChange={(e) => handleInputChange(question.id, e.target.value)}
          className="mt-1 block w-full"
        />
      );
    default:
      return null;
  }
};

export default FnolForm;
